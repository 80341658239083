import React from "react";
import Layout from "../components/layout";
import {Button} from "react-bootstrap";
import {AiOutlineMail} from "react-icons/ai";
import {MdQuestionAnswer} from "react-icons/md";
import {Link} from "gatsby";
import SEO from "../components/seo";

const Faq = () => {

    const QuestionAnswer = (props: any) => {
        return (
            <div>
                <div className="faq-question-q-box">Q.</div>
                <h4 className="faq-question">{props.question}</h4>
                <p className="faq-answer mb-4 pb-1 text-light"><>{props.answer}</>
                </p>
            </div>
        )
    }

    return (
        <Layout>
            <SEO title={'FAQ'} />

            <section className="py-5 bg-dark">
                <div className="container">
                    <div className="row pb-4">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <h1 className="mt-0 text-orange"><MdQuestionAnswer/></h1>
                                <h3 className={'text-light'}>Frequently Asked <span className="text-orange">Questions</span></h3>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-5 offset-lg-1">

                            <QuestionAnswer
                                question={'Which map provider do you use?'}
                                answer={
                                    <>
                                        We have one map provider for now <a href={'https://www.openstreetmap.org/'}
                                                                            target={'_blank'}>OpenStreetMap</a> which
                                        does not support traffic. We do however have other map providers on our roadmap,
                                        and hope to support traffic soon. Note that you can also naively model traffic
                                        or difficult driving conditions (e.g. during the winter) by scaling the travel
                                        times on vehicles when uploading your problem.
                                    </>
                                }
                            />

                            <QuestionAnswer
                                question={'Do you have an admin portal?'}
                                answer={
                                    <>
                                        Yes. We have an admin portal where you can <a
                                        href={'https://www.app.greengard.no/login/'} target={'_blank'}>login</a> and get
                                        an overview of your optimization jobs, results and much more.
                                    </>
                                }
                            />

                        </div>

                        <div className="col-lg-5">

                            <QuestionAnswer
                                question={'How large can my problem be?'}
                                answer={
                                    <>
                                        We do not have any limit on the number of jobs you can submit in a single
                                        request. We adjust the memory size in our backend servers according to the
                                        problem being submitted. However, as in any optimization problem, problems of
                                        larger scale (or/and many constraints) are in general harder to solve and needs
                                        more optimization time.
                                    </>
                                }
                            />

                            <QuestionAnswer
                                question={'Do you have a driver app?'}
                                answer={
                                    <>
                                        Currently we do not have an app for drivers. However, you can easily integrate
                                        with your own solution by feeding the result into your drivers favorite app.
                                    </>
                                }
                            />

                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <p className="text-light mt-2">
                                    Did you not find the information you were looking for?
                                </p>

                                <Button as={Link} to={'/contact'} className="btn-orange btn-sm">
                                    <AiOutlineMail
                                        className={'me-1'}
                                        style={{marginBottom: '2px'}}
                                    /> Contact us
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Faq